<template>
  <div class="page__pagination">
    <ul class="pagination">
      <li class="page-item" @click="decrementPagination">
        <span class="page-link arrow"> ‹ </span>
      </li>
      <li v-for="(item, index) in buttonsCount" :key="index" class="page-item">
        <button
          type="button"
          :class="['page-link', { active: currentPagination === item }]"
          @click="$emit('paginationChanged', item)"
        >
          {{ item }}
        </button>
      </li>
      <li class="page-item" @click="incrementPagination">
        <span class="page-link arrow"> › </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PaginationVue",
  props: {
    totalRows: {
      type: Number,
    },
    perMenu: {
      type: Number,
    },
    currentPagination: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      buttonsCount: Math.ceil(this.totalRows / this.perMenu),
    };
  },
  methods: {
    decrementPagination() {
      let decrementedPagination = JSON.parse(
        JSON.stringify(this.currentPagination)
      );
      decrementedPagination--;
      if (this.currentPagination > 1)
        this.$emit("paginationChanged", decrementedPagination);
    },
    incrementPagination() {
      let incrementedPagination = JSON.parse(
        JSON.stringify(this.currentPagination)
      );
      incrementedPagination++;
      if (this.currentPagination < this.buttonsCount)
        this.$emit("paginationChanged", incrementedPagination);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.page-link {
  color: $white;
  background-color: $purple;
  border: none;
  font-family: "Lato";
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem 0.8rem;
  margin: 0 10px;
  border-radius: 3px;
  user-select: none;

  &.active {
    color: $dark_text;
    background-color: transparent;
  }

  &.arrow {
    cursor: pointer;
    color: $dark_text;
    background-color: transparent;
  }

  &:focus {
    outline: none;
  }
}
</style>
